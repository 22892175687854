<template>
  <div>
    <card-component>
      <div class="is-flex is-justify-content-space-between is-align-items-center mb-3">
        <div class="is-flex">
          Total Data: <span class="has-text-primary has-text-weight-bold ml-2">{{ harvest.data.length }}</span>
        </div>
        <div class="has-text-right mb-2">
          <b-button type="is-primary" @click="exportToCsv">Export to CSV</b-button>
        </div>
      </div>
      <b-table
        :loading="harvest.loading"
        :paginated="paginated"
        :per-page="perPage"
        :striped="true"
        :hoverable="true"
        default-sort="total"
        default-sort-direction="desc"
        :data='harvest.data'>

        <b-table-column label="City" field="city" sortable v-slot="props" searchable>
          {{ props.row.city }}
        </b-table-column>
        <!--      <b-table-column label="Location" field="location" sortable v-slot="props">-->
        <!--        {{ props.row.location }}-->
        <!--      </b-table-column>-->
        <b-table-column label="Total" field="total" sortable v-slot="props">
          {{ $numberFormat(props.row.total) }} Kg
        </b-table-column>

        <template #detail="props">

          {{ props.row.infoLoading }}

          <div class="columns" v-if="!props.row.infoLoading">
            <div class="column" v-if="props.row.user">
              <div class="columns is-gapless mb-2">
                <div class="column">Owner</div>
                <div class="column">:
                  <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                        @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ props.row.user ? props.row.user.username : props.row.user_id }}
                  </span>
                </div>
              </div>
              <div class="columns is-gapless mb-2">
                <div class="column">Phone Number</div>
                <div class="column">:
                  <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                        @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ props.row.user ? props.row.user.phone : '' }}
                  </span>
                </div>
              </div>
              <div class="columns is-gapless mb-2">
                <div class="column">Email</div>
                <div class="column">:
                  <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                        @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ props.row.user ? props.row.user.email : '' }}
                  </span>
                </div>
              </div>
            </div>
            <div class="column" v-if="props.row.info">
              <div class="columns is-gapless mb-2">
                <div class="column">Total Block</div>
                <div class="column">: <span class="has-text-weight-bold ml-2">{{ props.row.info.block_count }}</span>
                </div>
              </div>
              <div class="columns is-gapless mb-2">
                <div class="column">Total Pond</div>
                <div class="column">: <span class="has-text-weight-bold ml-2">{{ props.row.info.pond_count }}</span>
                </div>
              </div>
              <div class="columns is-gapless">
                <div class="column">Total User</div>
                <div class="column">: <span class="has-text-weight-bold ml-2">{{ props.row.info.user_count }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="loader" style="height: 25px; width: 25px;margin: 0 auto" v-else></div>

        </template>

        <section class="section" slot="empty">
          <div class="content has-text-grey has-text-centered">
            <template v-if="harvest.loading">
              <p>
                <b-icon icon="dots-horizontal" size="is-large"/>
              </p>
              <p>Fetching data...</p>
            </template>
            <template v-else>
              <p>
                <b-icon icon="emoticon-sad" size="is-large"/>
              </p>
              <p>Nothing's there&hellip;</p>
            </template>
          </div>
        </section>
      </b-table>
    </card-component>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import CardComponent from "@/components/CardComponent";

export default {
  name: 'HarvestTable',
  components: {CardComponent},
  data() {
    return {
      paginated: true,
      perPage: 10,
      refresh: false,
    }
  },
  computed: {
    ...mapState('harvest', [
      'harvest',
    ]),

  },
  methods: {
    clickToCopy(e) {
      const el = document.createElement('textarea');
      el.value = e.target.innerText;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.$swal({
        icon: 'success',
        title: 'Copied to clipboard',
      })
    },

    exportToCsv() {
      let data = this.harvest.data.sort((a, b) => b.total > a.total ? 1 : 1).map(e => {
        return [
          e.city,
          e.total,
        ]
      })

      let csvContent = "data:text/csv;charset=utf-8,";

      data = [
        ['City', 'Total'],
        ...data
      ]

      data.forEach(function (rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Sgara Harvest - " + this.$dateFormat() + '.csv');
      document.body.appendChild(link); // Required for FF

      link.click();
    },
  }
}
</script>
