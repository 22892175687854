<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">
      <card-component>
        <div class="is-flex">

          <b-select v-model="type" @input="getData" class="mr-2">
            <option value="">All</option>
            <option value="month">Monthly</option>
            <option value="year">Yearly</option>
            <!--            <option :value="year" v-for="year in years" :key="year">{{ year }}</option>-->
          </b-select>

          <b-datepicker type="month"
                        placeholder="Select Month"
                        v-model="monthlyDate"
                        locale="id-ID"
                        v-if="type === 'month'"
                        @input="getData">
          </b-datepicker>

          <b-select v-model="yearlyDate" @input="getData" v-if="type === 'year'">
            <option :value="year" v-for="year in years" :key="year">{{ year }}</option>
          </b-select>

          <b-autocomplete
            v-model="search_city"
            :data="filteredCity"
            clearable
            open-on-focus
            class="mb-3 ml-2"
            v-if="type === 'month' && filteredCity.length"
            @select="option => selectedCity = option">
            <template #empty>No results found</template>
          </b-autocomplete>
        </div>
      </card-component>
      <harvest-metrics class="mb-4" :from="from" :to="to" v-if="!refresh"/>

      <card-component
        title="Count of Shrimp Size each province/region"
      >
        <div v-if="barData">
          <bar-chart :series="barData.series" :chart-options="barData.chartOptions" v-if="barData && !harvest_shrimp_size.loading"/>
        </div>
        <div v-else>
          No data for this {{ type }}, change the date to find another data
        </div>
      </card-component>

      <harvest-map class="mb-5" :from="from" :to="to" v-if="!refresh"/>

      <harvest-table v-if="!refresh"/>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HarvestMetrics from "@/components/Book/HarvestMetrics";
import BarChart from "@/components/Charts/BarChart";
import CardComponent from "@/components/CardComponent";
import HarvestTable from "@/components/Book/HarvestTable";
import {mapActions, mapState} from "vuex";
import HarvestMap from "@/components/Book/HarvestMap";

export default {
  name: 'BookHarvest',
  components: {
    HarvestMap,
    HarvestTable,
    CardComponent,
    BarChart,
    HarvestMetrics,
    TitleBar
  },
  data: () => {
    return {
      activeTab: 'Metrics',
      selectedCity: null,
      search_city: '',
      monthlyDate: new Date(),
      type: '',
      yearlyDate: new Date().getFullYear(),
      refresh: false,
      from: null,
      to: null,
    }
  },
  watch: {
    'harvest_shrimp_size.data': {
      deep: true,
      handler(value) {
        if (value.length) {
          this.selectedCity = value[0].city
          this.search_city = value[0].city
        }
      },
    }
  },
  computed: {
    years() {
      let current = 2020
      let res = []
      let currentYear = new Date().getFullYear()

      do {
        res.push(current)
        current++
      } while (current <= currentYear)

      return res
    },

    titleStack() {
      return ['SgaraBook', 'Harvest']
    },
    ...mapState('harvest', [
      'harvest',
      'harvest_shrimp_size',
    ]),
    filteredCity() {
      return this.harvest_shrimp_size.data.filter(e => e.fresh > 0).reduce((a, b) => {
        let find = a.find(e => e === b.city)

        if (!find) a.push(b.city)

        return a
      }, []).filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.search_city.toLowerCase()) >= 0
      })
    },
    barData() {
      if (this.selectedCity) {
        let data = this.harvest_shrimp_size.data

        if (this.type === 'month') {
          data = data.filter(e => e.city === this.selectedCity).sort((a, b) => a.size > b.size ? 1 : -1)
          data = data.reduce((a, b) => {
            let size = Math.ceil(b.size / 10) * 10
            let find = a.find(e => e.size === size)
            if (!find) {
              a.push({
                size: size,
                fresh: b.fresh,
              })
            } else {
              find.fresh += b.fresh
            }
            return a
          }, []).map(e => {
            e.size = (e.size - 9) + '-' + e.size
            return e
          })
        } else {
          data = data.reduce((a, b) => {
            let find = a.find(e => e.month === b.month)
            if (find) find.fresh += b.fresh
            else a.push({
              fresh: b.fresh,
              month: b.month,
            })
            return a
          }, [])
        }

        if (!data.length) return null

        return {
          series: [{
            name: 'Shrimp',
            type: 'area',
            data: data.map(e => e.fresh)
          }],
          chartOptions: {
            chart: {
              height: 350,
              type: 'bar',
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            fill: {
              type: 'solid',
              opacity: 1,
            },
            labels: data.map(e => this.type === 'month' ? e.size : e.month),
            markers: {
              size: 0
            },
            xaxis: {
              categories: data.map(e => this.type === 'month' ? e.size : e.month), labels: {
                formatter: function (value) {
                  return value;
                }
              }
            },
            tooltip: {
              shared: true,
              intersect: false,
              y: {
                formatter: function (y) {
                  if (typeof y !== "undefined") {
                    return y + " Kg";
                  }
                  return y;
                }
              }
            }
          }
        }
      }
      return null
    }
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions('harvest', [
      'getShrimpSizeChart',
      'getShrimpSizeChartByMonth',
      'getShrimpSizeChartByYear',
    ]),
    getData() {
      this.from = null
      this.to = null

      if (this.type === 'month') {
        this.from = this.$timestamp(this.$moment(this.monthlyDate).startOf('month'))
        this.to = this.$timestamp(this.$moment(this.monthlyDate).endOf('month'))
      } else if (this.type === 'year') {
        let date = this.$moment().set({year: this.yearlyDate})
        this.from = this.$timestamp(date.startOf('year'))
        this.to = this.$timestamp(date.endOf('year'))
      }

      if (this.type === 'month') {
        let monthlyDate = this.$moment(this.monthlyDate)

        let month = monthlyDate.month() + 1
        let year = monthlyDate.year()

        this.getShrimpSizeChartByMonth({month, year})
      } else {
        this.getShrimpSizeChartByYear({year: this.yearlyDate})
      }

      this.refresh = true
      this.$nextTick(() => {
        this.refresh = false
      })
    },
  },
}
</script>
