<template>
  <div>
    <tiles>
      <card-widget
        class="tile is-child"
        type="is-success"
        icon="circle-slice-3"
        :number="metrics.partial_harvest.value"
        label="Count of Partial Harvest"
      >
        <b-loading :is-full-page="false" v-model="metrics.partial_harvest.loading"></b-loading>
      </card-widget>
      <card-widget
        class="tile is-child"
        type="is-success"
        icon="circle-slice-8"
        :number="metrics.total_harvest.value"
        label="Count of Total Harvest"
      >
        <b-loading :is-full-page="false" v-model="metrics.total_harvest.loading"></b-loading>
      </card-widget>
      <card-widget
        class="tile is-child"
        type="is-success"
        icon="arrow-up-bold-circle"
        :number="metrics.fresh_shrimp.value"
        suffix=" Kg"
        label="Fresh Shrimp Harvest"
      >
        <b-loading :is-full-page="false" v-model="metrics.fresh_shrimp.loading"></b-loading>
      </card-widget>
    </tiles>
  </div>
</template>

<script>
import Tiles from "@/components/Tiles";
import CardWidget from "@/components/CardWidget";
import {mapActions, mapState} from "vuex";

export default {
  name: "HarvestMetrics",
  components: {CardWidget, Tiles},
  props: {
    from: Number,
    to: Number,
  },
  computed: {
    ...mapState('harvest', [
      'metrics',
    ]),
  },
  created() {
    let payload = {}
    if (this.from) payload.from = this.from
    if (this.to) payload.to = this.to
    this.countPartialHarvest(payload)
    this.countTotalHarvest(payload)
    this.countFreshShrimp(payload)
  },
  methods: {
    ...mapActions('harvest', [
      'countPartialHarvest',
      'countTotalHarvest',
      'countFreshShrimp',
    ]),
  }
}
</script>
